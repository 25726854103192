
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../../assets/logo-altred.png';

function Cancel(){
  const location = useLocation();
  const history = useHistory();
  function goBack(){
      history.goBack();
  }
  return (
    <div className='container mx-auto text-center'>
      <img className='' src={Logo} />
      <h2 className='text-center'>To Cancel:</h2>
      <Link to={"/"}><h1 className='text-center'><button className='btn btn-lg btn-login'>Cancel</button></h1></Link>
      <h2 className='text-center pb-1'>To return to the payment page press the button:</h2>
      
      <button className="btn btn-lg btn-login" type="button" onClick={goBack}>Back to Payment &#8617;</button>
      

    </div>
  );
}


  


export default Cancel;
