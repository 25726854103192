import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import './evento-cadastro.css';
import Navbar from '../../components/navbar/';

import firebase from '../../config/firebase';

function EventoCadastro(props){

    const [carregando, setCarregando] = useState();
    const [msgTipo, setMsgTipo] = useState();
    const [titulo, setTitulo] = useState();
    const [tipo, setTipo] = useState();
    const [detalhes, setDetalhes] = useState();
    const [data, setData] = useState();
    const [hora, setHora] = useState();
    const [arquivo, setArquivo] = useState();
    const [imagem, setImagem] = useState();
    const usuarioEmail = useSelector(state => state.usuarioEmail);

    const storege = firebase.storage();
    const db = firebase.firestore();

    useEffect(() => {
        if(props.match.params.id){
            firebase.firestore().collection('arquivos').doc(props.match.params.id).get().then(async (resultado) => {
                setTitulo(resultado.data().titulo)
                setDetalhes(resultado.data().detalhes)
                setTipo(resultado.data().tipo)
                setData(resultado.data().data)
                setHora(resultado.data().hora)
                setArquivo(resultado.data().arquivo)
                setImagem(resultado.data().imagem)
            });
        }

        
	}, [])

    function atualizar(){
        setMsgTipo(null);
        setCarregando(1);

        storege.ref(`videos/${imagem.name}`).put(imagem);
        storege.ref(`videos/${arquivo.name}`).put(arquivo).then(() => {
            db.collection('arquivos').doc(props.match.params.id).update({
                titulo: titulo,
                tipo: tipo,
                detalhes: detalhes,
                data: data,
                hora: hora,
                arquivo: arquivo.name ? arquivo.name: arquivo,
                imagem: imagem.name ? imagem.name: imagem,

            }).then(()=>{
                setCarregando(0);
                setMsgTipo('sucesso');
            }).catch(erro => {
            setCarregando(0);    
            setMsgTipo('erro');
            });
        });    
           
        
    }
   

    function cadastrar(){
        setMsgTipo(null);
        setCarregando(1);

        storege.ref(`videos/${imagem.name}`).put(imagem);
        storege.ref(`videos/${arquivo.name}`).put(arquivo).then(() => {
            db.collection('arquivos').add({
                titulo: titulo,
                tipo: tipo,
                detalhes: detalhes,
                data: data,
                hora: hora,
                usuario: usuarioEmail,
                visualizacoes: 0,
                arquivo: arquivo.name,
                imagem: imagem.name,
                publico: 1,
                criacao: new Date()

            }).then(()=>{
                setCarregando(0);
                setMsgTipo('sucesso');
            }).catch(erro => {
            setCarregando(0);    
            setMsgTipo('erro');
            });
        });    
        
    }
    
    return(
        <>
        <Navbar/>
        <div className="col-12">
            <div className="row">
                <h3 className="mx-auto mt-5">{props.match.params.id ? 'Editar arquivo' : 'Novo arquivo'}</h3>
            </div>        
        </div>
        <form className="container">
            <div className="form-group">
                <label>Título</label>
                <input onChange={ (e) => setTitulo(e.target.value) }  type="text" className="form-control" value={titulo && titulo}/>
            </div>
            <div className="form-group">
                <label>Tipo do Evento:</label>
                <select onChange={ (e) => setTipo(e.target.value) } type="text" className="form-control" value={tipo && tipo}>
                    <option disable selected value>-- select a type --</option>
                    <option>pandemic</option>
                    <option>hidden-story</option>
                    <option>flat-earth</option>
                    <option>new-world-order</option>
                    <option>health</option>
                    <option>free-energy</option>
                    <option>books</option>
                    
                </select>
            </div>
            <div className="form-group row">
                <div className="form-group col-12">
                    <label>Descrição do vídeo</label>
                    <textarea onChange={ (e) => setDetalhes(e.target.value) } className="form-control" rows="3" value={detalhes && detalhes}/>
                </div>
                
                <div className="form-group col-6">
                    <label>Data:</label>
                    <input onChange={ (e) => setData(e.target.value) } type="date" className="form-control" value={data && data}/>
                </div>
                <div className="form-group col-6">
                    <label>Hora:</label>
                    <input onChange={ (e) => setHora(e.target.value) } type="time" className="form-control" value={hora && hora}/>
                </div>
                <div className="form-group col-6">
                    <label>Upload de Imagem</label>
                    <input onChange={ (e) => setArquivo(e.target.files[0]) } type="file" className="form-control"/>
                </div>
                <div className="form-group col-6">
                    <label>Upload de Vídeo</label>
                    <input onChange={ (e) => setImagem(e.target.files[0]) } type="file" className="form-control"/>
                </div>
                

                
            </div>
            <div className="row">
                {
                    carregando > 0 ?<div className="spinner-border text-dark mx-auto" role="status"><span className="sr-only"> Loading...</span></div>
                    :<button type="button" onClick={props.match.params.id ? atualizar : cadastrar}  className="btn bn-lg btn-block mt-3 mb-5 btn-cadastro">{props.match.params.id ? 'Publicar arquivo Editado' : 'Publicar'}</button>
                }
            </div>
            
        </form>
        
        
        <div className="msg-login text-center mt-5">
                
                {msgTipo === 'sucesso' && <span><strong>Wow!</strong> Arquivo Publicado &#128526;</span>}
                {msgTipo === 'erro' && <span><strong>Ops!</strong> {msgTipo} Não foi possível publicar o arquivo! &#9888;</span>}
                       
        </div>
       

        </>
    )
}

export default EventoCadastro;