import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import '../evento-cadastro/evento-cadastro.css';
import Navbar from '../../components/navbar/';

import firebase from '../../config/firebase';

function ImagemCadastro(props){

    const [carregando, setCarregando] = useState();
    const [msgTipo, setMsgTipo] = useState();
    const [titulo, setTitulo] = useState();
    const [tipo, setTipo] = useState();
    const [detalhes, setDetalhes] = useState();
    const [data, setData] = useState();
    const [hora, setHora] = useState();
    const [arquivo, setArquivo] = useState();
    const usuarioEmail = useSelector(state => state.usuarioEmail);

    const storege = firebase.storage();
    const db = firebase.firestore();

    useEffect(() => {
        if(props.match.params.id){
            firebase.firestore().collection('fotos').doc(props.match.params.id).get().then(async (resultado) => {
                setTitulo(resultado.data().titulo)
                setDetalhes(resultado.data().detalhes)
                setTipo(resultado.data().tipo)
                setData(resultado.data().data)
                setHora(resultado.data().hora)
                setArquivo(resultado.data().arquivo)
            });
        }

        
	}, [])

    function atualizar(){
        setMsgTipo(null);
        setCarregando(1);

        
        storege.ref(`imagens/${arquivo.name}`).put(arquivo).then(() => {
            db.collection('fotos').doc(props.match.params.id).update({
                titulo: titulo,
                tipo: tipo,
                detalhes: detalhes,
                data: data,
                hora: hora,
                arquivo: arquivo.name ? arquivo.name: arquivo,


            }).then(()=>{
                setCarregando(0);
                setMsgTipo('sucesso');
            }).catch(erro => {
            setCarregando(0);    
            setMsgTipo('erro');
            });
        });    
           
        
    }
   

    function cadastrar(){
        setMsgTipo(null);
        setCarregando(1);

        
        storege.ref(`imagens/${arquivo.name}`).put(arquivo).then(() => {
            db.collection('fotos').add({
                titulo: titulo,
                tipo: tipo,
                detalhes: detalhes,
                data: data,
                hora: hora,
                usuario: usuarioEmail,
                visualizacoes: 0,
                arquivo: arquivo.name,
                
                publico: 1,
                criacao: new Date()

            }).then(()=>{
                setCarregando(0);
                setMsgTipo('sucesso');
            }).catch(erro => {
            setCarregando(0);    
            setMsgTipo('erro');
            });
        });    
        
    }
    
    return(
        <>
        <Navbar/>
        <div className="col-12">
            <div className="row">
                <h3 className="mx-auto mt-5">{props.match.params.id ? 'Editar arquivo' : 'Novo arquivo'}</h3>
            </div>        
        </div>
        <form className="container">
            <div className="form-group">
                <label>Título</label>
                <input onChange={ (e) => setTitulo(e.target.value) }  type="text" className="form-control" value={titulo && titulo}/>
            </div>
            <div className="form-group">
                <label>Tipo do Evento:</label>
                <select onChange={ (e) => setTipo(e.target.value) } type="text" className="form-control" value={tipo && tipo}>
                    <option disable selected value>-- Selecione um tipo --</option>
                    <option>pandemia</option>
                    <option>historia-escondida</option>
                    <option>terra-plana</option>
                    <option>nova-ordem-mundial</option>
                    <option>covid</option>
                </select>
            </div>
            <div className="form-group row">
                <div className="form-group col-12">
                    <label>Descrição do vídeo</label>
                    <textarea onChange={ (e) => setDetalhes(e.target.value) } className="form-control" rows="3" value={detalhes && detalhes}/>
                </div>
                
                <div className="form-group col-6">
                    <label>Data:</label>
                    <input onChange={ (e) => setData(e.target.value) } type="date" className="form-control" value={data && data}/>
                </div>
                <div className="form-group col-6">
                    <label>Hora:</label>
                    <input onChange={ (e) => setHora(e.target.value) } type="time" className="form-control" value={hora && hora}/>
                </div>
                <div className="form-group col-6">
                    <label>Upload de Imagem</label>
                    <input onChange={ (e) => setArquivo(e.target.files[0]) } type="file" className="form-control"/>
                </div>
                
                

                
            </div>
            <div className="row">
                {
                    carregando > 0 ?<div className="spinner-border text-dark mx-auto" role="status"><span className="sr-only"> Loading...</span></div>
                    :<button type="button" onClick={props.match.params.id ? atualizar : cadastrar}  className="btn bn-lg btn-block mt-3 mb-5 btn-cadastro">{props.match.params.id ? 'Publicar arquivo Editado' : 'Publicar'}</button>
                }
            </div>
            
        </form>
        
        
        <div className="msg-login text-center mt-5">
                
                {msgTipo === 'sucesso' && <span><strong>Wow!</strong> Arquivo Publicado &#128526;</span>}
                {msgTipo === 'erro' && <span><strong>Ops!</strong> {msgTipo} Não foi possível publicar o arquivo! &#9888;</span>}
                       
        </div>
       

        </>
    )
}

export default ImagemCadastro;