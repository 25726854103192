import React from 'react';

import { Link } from 'react-router-dom';

import {useSelector} from 'react-redux';




function Unsubscribe() {



    const usuarioEmail = useSelector(state => state.usuarioEmail);




    return (
        





        <>
        
            <div id="top-footer" className="section">

                <div className="container">
                    <div className="col-md-12 text-center">
                        
                        <div className="footer-widget about-widget">
                            <div className="footer-logo">
                            <Link className="article-category text-white" to='/home'><img src="img/logo-altred.png" /></Link>
                                
                            </div>
                        </div>
                        <h1>Cancellation</h1>










                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="footer-widget subscribe-widget">
                                <div className="widget-title">
                                    <h2 className="title">Please tell us the reason for your cancellation so that we can improve our website.</h2>
                                </div>
                                <form action="unsubscribe.php" method="post"> 
                                    <input className="input" name="email" type="hidden" value={usuarioEmail} />
                                    <input className="input" name="subject" type="hidden" value="cancelamento" />
                                    <textarea className="input form-control mt-2" rows="3" name="message" type="text" placeholder="Leave your message" />
                                    <button type="submit" className="input-btn">Cancel account</button>
                                </form>
                            </div>
                        </div>
                        

                        


                    




                    </div>

                </div>

            </div>



            <div id="bottom-footer" className="section">

                <div className="container">

                    <div className="row">





                        <div className="col-md-12 col-md-pull-12">
                            <div className="footer-copyright text-center">
                                <span>
                                    Copyright &copy;All rights reserved Redpill Files
                                </span>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </>

    )
}
export default Unsubscribe;