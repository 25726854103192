import React, { useState, useEffect } from 'react';
import '../home/home.css';

import Navbar from '../../components/navbar/';

import Footer from '../../components/footer/';
import Imagens from '../../components/imagens';
import firebase from '../../config/firebase';


function ImagemDoDia({ match }) {


    const [pesquisa, setPesquisa] = useState('');
    const [arquivos, setArquivos] = useState([]);
    let listaarquivos = [];
    
    const [arquivosFooter, setArquivosFooter] = useState([]);
    let listaarquivosFooter = [];
    const [ultimo, setUltimo] = useState(8);


    useEffect(() => {
        firebase.firestore().collection('arquivos').orderBy('data', 'desc').limit(1).get().then(async (resultado) => {

            await resultado.docs.forEach(doc => {

                
                   

                        listaarquivosFooter.push({
                            id: doc.id,
                            ...doc.data()

                        })
                    



                

            })
            setArquivosFooter(listaarquivosFooter);
        })

   

        if (match.params.parametro) {

            if(pesquisa){
                firebase.firestore().collection('fotos').where('tipo', '==', match.params.parametro).get().then(async (resultado) => {
                await resultado.docs.forEach(doc => {

                    if (doc.data().titulo) {


                        if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {

                            listaarquivos.push({
                                id: doc.id,
                                ...doc.data()

                            })
                        }

                    }


                })

                setArquivos(listaarquivos);

            })}
            else{
                firebase.firestore().collection('fotos').limit(ultimo).where('tipo', '==', match.params.parametro).get().then(async (resultado) => {
                    await resultado.docs.forEach(doc => {
    
                        if (doc.data().titulo) {
    
    
                            if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {
    
                                listaarquivos.push({
                                    id: doc.id,
                                    ...doc.data()
    
                                })
                            }
    
                        }
    
    
                    })
    
                    setArquivos(listaarquivos);
    
                })
            }

        }

        else if (pesquisa) {

            firebase.firestore().collection('fotos').orderBy('visualizacoes', 'desc').get().then(async (resultado) => {

                await resultado.docs.forEach(doc => {
                    if (doc.data().titulo) {


                        if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {

                            listaarquivos.push({
                                id: doc.id,
                                ...doc.data()

                            })
                        }

                    }


                })


                setArquivos(listaarquivos);


            })
        }
        else {

            firebase.firestore().collection('fotos').orderBy('data', 'desc').limit(ultimo).get().then(async (resultado) => {

                await resultado.docs.forEach(doc => {
                    if (doc.data().titulo) {


                        if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {

                            listaarquivos.push({
                                id: doc.id,
                                ...doc.data()

                            })
                        }

                    }


                })


                setArquivos(listaarquivos);


            })
        }



    }, [pesquisa, ultimo]);

    const verMais = () => {
        setUltimo(ultimo + 8);
    }


    return (
        <>

            <Navbar />

            <div className="row">
                <div className='col-md-12'>
                    <div className='row'>

                        

                    </div>
                </div>

            </div>

            <div className="row justify-content-md-center p-5">
                <input onChange={(e) => setPesquisa(e.target.value)} type="text" className="col-sm col-md-5 text-center form-control" placeholder="Search image..." />
            </div>
            <div className="section">

                <div className="col-md-10 container">

                    <div className="row">

                        <div className="col-md-12">

                            <div className="row">


                                {arquivos.map(item => <Imagens Key={item.id} titulo={item.titulo} arquivo={item.arquivo} data={item.data} detalhes={item.detalhes} hora={item.hora} tipo={item.tipo} visualizacoes={item.visualizacoes} />)}


                            </div>


                        </div>


                        <div className="article-pagination">
                            <button type="button" onClick={verMais} className="btn btn-danger btn-lg">show more...</button>

                        </div>


                    </div>

                </div>



            </div>

            <footer id="footer">
            {arquivosFooter.map(item => <Footer Key={item.id} titulo={item.titulo} arquivo={item.arquivo} data={item.data} detalhes={item.detalhes} hora={item.hora} tipo={item.tipo} visualizacoes={item.visualizacoes} />)}
                
            </footer>

        </>
    )
}
export default ImagemDoDia;