import React, { useState } from 'react';
import './usuario-recuperar-senha.css';
import Navbar from '../../components/navbar/';

import firebase from '../../config/firebase';
import 'firebase/auth';

function UsuarioRecuperarSenha() {

    const [email, setEmail] = useState();
    const [msg, setMsg] = useState();

    function recuperaSenha() {
        firebase.auth().sendPasswordResetEmail(email).then(resultado => {
            setMsg('We send a link to your email for you to reset your password!');
        }).catch(erro => {
            setMsg('Check that the email provided is correct');
        })
    }
    return (
        <>
           
            

            <form className="text-center form-login mx-auto mt-5 d-grid">
            <div className="text-center mb-4 ">
                <img src="img/logo-altred.png" />
                    
                
                </div>

                <h3 className="mb-3 font-wheight-bold">Recover Password</h3>

                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control my-2" placeholder="Email" />

                <div className="msg my-2 text-center">
                    <span>{msg}</span>
                </div>
                <buttton onClick={recuperaSenha} type="button" className="btn btn-lg btn-block btn-enviar">Recover Password</buttton>

            </form>




        </>
    )
}
export default UsuarioRecuperarSenha;