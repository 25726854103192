import React, { useState, useEffect } from 'react';
import './home.css';

import Navbar from '../../components/navbar/';

import Footer from '../../components/footer/';
import ArquivosCard from '../../components/arquivos-card/';
import ArquivosSlider from '../../components/slider/';
import firebase from '../../config/firebase';


function Home({ match }) {


    const [pesquisa, setPesquisa] = useState('');
    const [arquivos, setArquivos] = useState([]);
    let listaarquivos = [];
    const [arquivosSlider, setArquivosSlider] = useState([]);
    let listaarquivosSlider = [];
    const [arquivosFooter, setArquivosFooter] = useState([]);
    let listaarquivosFooter = [];
    const [ultimo, setUltimo] = useState(16);


    useEffect(() => {
        firebase.firestore().collection('arquivos').orderBy('data', 'desc').limit(1).get().then(async (resultado) => {

            await resultado.docs.forEach(doc => {

                
                   

                        listaarquivosFooter.push({
                            id: doc.id,
                            ...doc.data()

                        })
                    



                

            })
            setArquivosFooter(listaarquivosFooter);
        })

       

        if (match.params.parametro) {
            
        
            

            if(pesquisa){
                firebase.firestore().collection('arquivos').where('tipo', '==', match.params.parametro).get().then(async (resultado) => {
                await resultado.docs.forEach(doc => {

                    if (doc.data().titulo) {
                        

                        if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {
                            
                            listaarquivos.push({
                                id: doc.id,
                                ...doc.data()

                            })
                        }

                    }


                })
                listaarquivos.sort(function(a,b) {
                    return a.data > b.data ? -1 : a.data < b.data ? 1 : 0;
                    
                });

                
                setArquivos(listaarquivos);

            })}
            else{
                
            
                firebase.firestore().collection('arquivos').limit(ultimo).where('tipo', '==', match.params.parametro).get().then(async (resultado) => {
                    await resultado.docs.forEach(doc => {
    
                        if (doc.data().titulo) {
    
    
                            if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {
    
                                listaarquivos.push({
                                    id: doc.id,
                                    ...doc.data()
    
                                })
                            }
    
                        }
    
    
                    })
                    listaarquivos.sort(function(a,b) {
                        return a.data > b.data ? -1 : a.data < b.data ? 1 : 0;
                        
                    });
    
                    setArquivos(listaarquivos);
    
                })
            }

        }

        else if (pesquisa) {
            firebase.firestore().collection('arquivos').orderBy('data', 'desc').limit(2).get().then(async (resultado) => {

            

                await resultado.docs.forEach(doc => {
    
                    
    
                            listaarquivosSlider.push({
                                id: doc.id,
                                ...doc.data()
    
                            })
                        
    
    
    
                    
    
                })
                setArquivosSlider(listaarquivosSlider);
            })
            
            

            firebase.firestore().collection('arquivos').orderBy('data', 'desc').get().then(async (resultado) => {

                await resultado.docs.forEach(doc => {
                    if (doc.data().titulo) {


                        if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {

                            listaarquivos.push({
                                id: doc.id,
                                ...doc.data()

                            })
                        }

                    }


                })


                setArquivos(listaarquivos);


            })
        }
        else {
            firebase.firestore().collection('arquivos').orderBy('data', 'desc').limit(2).get().then(async (resultado) => {

            

                await resultado.docs.forEach(doc => {
    
                    
    
                            listaarquivosSlider.push({
                                id: doc.id,
                                ...doc.data()
    
                            })
                        
    
    
    
                    
    
                })
                setArquivosSlider(listaarquivosSlider);
            })
           

            firebase.firestore().collection('arquivos').orderBy('visualizacoes', 'desc').limit(ultimo).get().then(async (resultado) => {

                await resultado.docs.forEach(doc => {
                    if (doc.data().titulo) {


                        if (doc.data().titulo.indexOf(pesquisa.toLowerCase()) >= 0) {

                            listaarquivos.push({
                                id: doc.id,
                                ...doc.data()

                            })
                        }

                    }


                })


                setArquivos(listaarquivos);


            })
        }



    }, [pesquisa, ultimo]);

    const verMais = () => {
        setUltimo(ultimo + 8);
    }


    return (
        <>

            <Navbar />

            <div className="row">
                <div className='col-md-12'>
                    <div className='row'>

                        {arquivosSlider.map(item => <ArquivosSlider Key={item.id} titulo={item.titulo} arquivo={item.arquivo} data={item.data} detalhes={item.detalhes} hora={item.hora} tipo={item.tipo} visualizacoes={item.visualizacoes} />)}

                    </div>
                </div>

            </div>

            <div className="row justify-content-md-center p-5">
                <input onChange={(e) => setPesquisa(e.target.value)} type="text" className="col-sm col-md-5 text-center form-control" placeholder="Search video..." />
            </div>
            <div className="section">

                <div className="col-md-10 container">

                    <div className="row">

                        <div className="col-md-12">

                            <div className="row">


                                {arquivos.map(item => <ArquivosCard Key={item.id} titulo={item.titulo} arquivo={item.arquivo} data={item.data} detalhes={item.detalhes} hora={item.hora} tipo={item.tipo} visualizacoes={item.visualizacoes} />)}


                            </div>


                        </div>


                        


                    </div>

                </div>
                <div className="article-pagination col-md-12 text-center">
                            <button type="button" onClick={verMais} className="btn btn-danger btn-lg">show more...</button>

                        </div>



            </div>

            <footer id="footer">
            {arquivosFooter.map(item => <Footer Key={item.id} titulo={item.titulo} arquivo={item.arquivo} data={item.data} detalhes={item.detalhes} hora={item.hora} tipo={item.tipo} visualizacoes={item.visualizacoes} />)}
                
            </footer>

        </>
    )
}
export default Home;