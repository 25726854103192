import React, { useEffect, useState } from 'react';
import firebase from '../../config/firebase';
import 'firebase/auth';

import { Link } from 'react-router-dom';

import './usuario-novo.css';

function NovoUsuario() {
    const [pesquisa, setPesquisa] = useState('');
    const [msgEmail, setMsgEmail] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [msgTipo, setMsgTipo] = useState('');
    const [msg, setMsg] = useState('');
    const [carregando, setCarregando] = useState('');

    function cadastrar() {
        setMsgTipo(null);
        setCarregando(1);

        if (!email || !senha) {
            setCarregando(0);
            setMsgTipo('error')
            setMsg('You need to enter email and password to register!')
            return;
        }

        firebase.auth().createUserWithEmailAndPassword(email, senha).then(resultado => {
            setCarregando(0);
            setMsgTipo('success')
        }).catch(erro => {
            setCarregando(0);
            setMsgTipo('error')
            setMsg(erro.message);
            // switch(erro.message)
            // {
            //     case 'Password should be at least 6 characters':
            //         setMsg('A senha deve ter pelo menos 6 caracteres');
            //         break;
            //     case 'The email address is badly formatted.':
            //         setMsg('O formato de seu email é inválido!');
            //         break;
            //     case 'The email address is already in use by another account.':
            //         setMsg('Este email já está sendo utilizado por outro usuário!');
            //         break;
            //     default:
            //         setMsg('Não foi possível cadastrar. Tente novamente mais tarde!');
            //         break;            

            // }
        })
    }
    useEffect( ()=>{
        if(pesquisa){
            if(pesquisa === email){
                setMsgEmail('sucesso');
                
            }
                
    
            else{
                setMsgEmail('error');
                
            }
        }
           
    },[pesquisa]);

    return (
        <>


            <div className="form-cadastro">
                <form className="text-center form-login mx-auto mt-5 d-grid gap-2">
                    <div className="text-center mb-4 ">
                        <img src="img/logo-altred.png" />
                    </div>
                    <h1 className="h3 mb-3 text-black font-weight-bold">Sign up</h1>

                    <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control my-2" placeholder="email" />
                    <input onChange={(e) => setPesquisa(e.target.value)} type="url" className="form-control my-2" placeholder="confirm email" />
                    {msgEmail === 'error' && <span><strong className='text-white'>Email does not match! </strong>&#128308;&#128308;&#128308;&#128308;</span>}
                    {msgEmail === 'sucesso' && <span><strong className='text-white'>Email Ok! </strong>&#9989;</span>}
                    <input onChange={(e) => setSenha(e.target.value)} type="password" className="form-control my-2" placeholder="password" />


                    {
                        carregando ? <div className="d-flex justify-content-center"><div className="spinner-border text-dark mx-auto" role="status"><span className="sr-only"> Loading...</span></div></div>
                            : <button onClick={cadastrar} type="button" className="btn btn-lg btn-block mt-3 mb-5 btn-cadastro">register</button>
                    }


                    <div className="msg-login text-center text-black mt-5">

                        {msgTipo === 'success' && <span><strong>Wow! </strong>User registered with {msgTipo}!  &#128526;</span>}
                        {msgTipo === 'success' && <span className="opcoes-login text-center mt-5">
                            <Link to="/login" className="mx-2">&#8594; Login</Link>

                        </span>}
                        {msgTipo === 'error' && <span className='text-white'><strong>Ops!</strong> {msg}  &#9888;</span>}

                    </div>


                </form>
            </div>
        </>
    )
}

export default NovoUsuario;