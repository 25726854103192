
import { loadStripe } from "@stripe/stripe-js";


import { useState } from "react";
import Helmet from 'react-helmet';

import Logo from '../../components/checkout/logo-alt.ico';









let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51Kag4nGv5QvcAKoL70pI9glGETyFrd4tvVG8PJ71tUd2hfc84e41pJcfIhZesZpjyBESQ8xKBajClMl0nZEH3e7m00PRwfrJcM", { locale: 'en' });

  }

  return stripePromise;
};

const Donation = () => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoadingA, setLoadingA] = useState(false);
 


  const itemA = {
    price: "price_1LO0d4Gv5QvcAKoLkTuztDOW",
    quantity: 1
  };


  const checkoutOptionsA = {
    lineItems: [itemA],
    mode: "payment",
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`
  };

  const redirectA = async () => {
    setLoadingA(true);
    console.log("redirectToCheckout");

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptionsA);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoadingA(false);
  };





 





  if (stripeError) alert(stripeError);

  return (
    <>
      
      <Helmet>
        <meta charset="utf-8" />

        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>Flat Earth Files</title>
        <link rel="icon" href="img/logo-alt.ico" />



        <link rel="stylesheet" href="css/animate.css" />

        <link rel="stylesheet" href="css/owl.carousel.min.css" />

        <link rel="stylesheet" href="css/all.css" />

        <link rel="stylesheet" href="css/flaticon.css" />
        <link rel="stylesheet" href="css/themify-icons.css" />

        <link rel="stylesheet" href="css/magnific-popup.css" />

        <link rel="stylesheet" href="css/slick.css" />

        <link rel="stylesheet" href="css/stylet.css" />

        <script src="jst/jquery-1.12.1.min.js"></script>

        <script src="jst/popper.min.js"></script>

        <script src="jst/bootstrap.min.js"></script>

        <script src="jst/jquery.magnific-popup.js"></script>

        <script src="jst/swiper.min.js"></script>



        <script src="jst/jquery.nice-select.min.js"></script>

        <script src="jst/owl.carousel.min.js"></script>


        <script src="jst/slick.min.js"></script>
        <script src="jst/jquery.counterup.min.js"></script>
        <script src="jst/waypoints.min.js"></script>

        <script src="jst/jquery.ajaxchimp.min.js"></script>
        <script src="jst/jquery.form.js"></script>
        <script src="jst/jquery.validate.min.js"></script>
        <script src="jst/mail-script.js"></script>





      </Helmet>




      <div className="body_bg">


        <section id="sign" className="pricing_part padding_top section_padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_tittle text-center">
                  <h2>Help Keep The Website And App Running!</h2>
                </div>
              </div>
            </div>
            <div id="" className="row justify-content-center">

              
              <div className="col-lg-3 col-sm-6">
                <div className="single_pricing_part">
                  <p>Donation</p><br/>
                  <h3>$15.00</h3>
                  <ul>
                    <p>To keep the site up and running, we pay for storage and server reads. If you can, help us with a donation. Thanks!</p>
                  </ul>

                  <button
                    className="btn_2"
                    onClick={redirectA}
                    disabled={isLoadingA}
                  >


                    <span className="text">{isLoadingA ? "Loading..." : "donate"}</span>

                  </button>
                </div>
              </div>
             
             
            </div>
          </div>
        </section>




      </div>
      <div id="top-end" className="section footerone px-3">

        <div className="container">

          <div className="row">

            <div className="col-md-6">

              <div className="footer-widget about-widget">
                <div className="footer-logo">
                  <p>Website and APP created to compile and store the world's censored truth.</p><br />
                  <img src={Logo} alt="" />

                </div>
              </div>










            </div>
            <div className="col-md-6">
              <div className="footer-widget subscribe-widget">
                <div className="widget-title">
                  <h2 className="title">Contact us!</h2>
                </div>
                <form action="contact.php" method="post">
                  <input className="input" name="email" type="email" placeholder="Your email here" />
                  <textarea className="input form-control mt-2" rows="3" name="message" type="text" placeholder="Leave your message" />
                  <button type="submit" className="input-btn">send</button>
                </form>
              </div>
            </div>

            <div className="col-md-3">

              <div className="footer-widget">
                <div className="widget-title">
                  <h2 className="title"></h2>
                </div>




              </div>

            </div>





          </div>

        </div>
        <div id="bottom-footer" className="section text-center">

          <div className="container">

            <div className="row">





              <div className="col-md-12 col-md-pull-12">
                <div className="footer-copyright">
                  <span>
                    Copyright &copy;All rights reserved Flat Earth Files
                  </span>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>











    </>

  );
};

export default Donation;
