import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo-altred.png';

function Success(){
  
  
  return (
    <div className='container mx-auto text-center'>
      <img className='' src={Logo} />
      <h2 className='text-center pb-3'>Thank you for your help! Let's keep striving to bring more content!</h2>
      <h2 className='text-center pb-3'>Click to go back home:</h2>
      <Link to="/"><h1 className='text-center'><button className='btn btn-lg btn-login'>Home</button></h1></Link>
      
      
      
      

    </div>
  )
}


  


export default Success;