import React, {useState} from 'react';
import './login.css';
import {Link, Redirect} from 'react-router-dom';

import firebase from '../../config/firebase';
import 'firebase/auth';

import {useSelector, useDispatch} from 'react-redux';


function Login(){

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [msgTipo, setMsgTipo] = useState('');

    const dispatch = useDispatch();

    function logar(){
        
        firebase.auth().signInWithEmailAndPassword(email, senha).then(resultado=>{
            setMsgTipo('sucesso')
            setTimeout(()=>{
                dispatch({type: 'LOG_IN', usuarioEmail: email});    
            },2000)
        }).catch(erro=> {
            setMsgTipo('erro')
        });

        
    }

   

    return(
        <div className="login-content d-flex align-items-center ">

            
                {useSelector(state => state.usuarioLogado) > 0 ? <Redirect to='/home' /> : null}
                
            <form className="form-signin mx-auto d-grid gap-2">
                <div className="text-center mb-4 ">
                <img src="img/logo-altred.png" />
                    <h1 className="h3 mb-3 font-weight-normal text-white font-weight-bold">Login</h1>
                
                </div>
        
                <input onChange={ (e) => setEmail(e.target.value) } type="email" id="inputEmail" className="form-control my-2" placeholder="Email"  />
                

                <input onChange={ (e) => setSenha(e.target.value) } type="password" id="inputPassword" className="form-control my-2" placeholder="Password" />
                
                <button onClick={logar} className="btn btn-block btn-lg btn-login" type="button" >log in</button>

                <div className="msg-login text-center text-white mt-5">
                
                {msgTipo === 'sucesso' && <span><strong>Wow!</strong> You are connected! &#128526;</span>}
                {msgTipo === 'erro' && <span><strong>Oops!</strong> {msgTipo} Make sure the password and username are correct! &#9888;</span>}
                       
                </div>

                <div className="opcoes-login text-center mt-5">
                    <Link to="recoverpassword" className="mx-2">Recover password</Link>
                    <span className="text-white">&#9782;</span>
                    <span onClick={() => window.location.replace("/#sign")}>
                    <Link><span className="mx-2">I want to sign up</span></Link>
                    </span>
                </div>
            </form>
      </div>
    )
}

export default Login;