
import { loadStripe } from "@stripe/stripe-js";


import { useState } from "react";
import Helmet from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import Logo from '../../components/checkout/logo-alt.ico';
// import Anubis from '../../assets/anubis.mp4';
import Mao from '../../assets/mao.mp4';
// import video1 from '../../assets/finalprojectcerto.mp4';
// import video2 from '../../assets/video2.mp4';

import { useSelector } from 'react-redux';






let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51Kag4nGv5QvcAKoL70pI9glGETyFrd4tvVG8PJ71tUd2hfc84e41pJcfIhZesZpjyBESQ8xKBajClMl0nZEH3e7m00PRwfrJcM", { locale: 'en' }, { currency: 'usd' });

  }

  return stripePromise;
};

const Checkout = () => {
  const [stripeError, setStripeError] = useState(null);
  const [isLoadingA, setLoadingA] = useState(false);
  const [isLoadingB, setLoadingB] = useState(false);
  const [isLoadingC, setLoadingC] = useState(false);


  const itemA = {
    price: "price_1LMzBhGv5QvcAKoLd2Kg6aun",
    quantity: 1
  };


  const checkoutOptionsA = {
    lineItems: [itemA],
    mode: "subscription",
    successUrl: `${window.location.origin}/newuser/sign/cod/userwascrthtx27`,
    cancelUrl: `${window.location.origin}/cancel`
  };

  const redirectA = async () => {
    setLoadingA(true);
    console.log("redirectToCheckout");

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptionsA);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoadingA(false);
  };



  const itemB = {
    price: "price_1M9naXGv5QvcAKoLaLgoiwe1",
    quantity: 1
  };

  const checkoutOptionsB = {
    lineItems: [itemB],
    mode: "payment",
    successUrl: `${window.location.origin}/newuser/sign/cod/userwascrthtx27`,
    cancelUrl: `${window.location.origin}/cancel`
  };

  const redirectB = async () => {
    setLoadingB(true);
    console.log("redirectToCheckout");

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptionsB);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoadingB(false);
  };


  const itemC = {
    price: "price_1M9ncGGv5QvcAKoLErBmxK0l",
    quantity: 1
  };

  const checkoutOptionsC = {
    lineItems: [itemC],
    mode: "payment",
    successUrl: `${window.location.origin}/newuser/sign/cod/userwascrthtx27`,
    cancelUrl: `${window.location.origin}/cancel`
  };

  const redirectC = async () => {
    setLoadingC(true);
    console.log("redirectToCheckout");

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptionsC);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoadingC(false);
  };





  if (stripeError) alert(stripeError);

  return (
    <>
      {useSelector(state => state.usuarioLogado) > 0 ? <Redirect to='/home' /> : null}
      <Helmet>
        <meta charset="utf-8" />

        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>Flat Earth Files</title>
        <link rel="icon" href="img/logo-alt.ico" />



        <link rel="stylesheet" href="css/animate.css" />

        <link rel="stylesheet" href="css/owl.carousel.min.css" />

        <link rel="stylesheet" href="css/all.css" />

        <link rel="stylesheet" href="css/flaticon.css" />
        <link rel="stylesheet" href="css/themify-icons.css" />

        <link rel="stylesheet" href="css/magnific-popup.css" />

        <link rel="stylesheet" href="css/slick.css" />

        <link rel="stylesheet" href="css/stylet.css" />

        <script src="jst/jquery-1.12.1.min.js"></script>

        <script src="jst/popper.min.js"></script>

        <script src="jst/bootstrap.min.js"></script>

        <script src="jst/jquery.magnific-popup.js"></script>

        <script src="jst/swiper.min.js"></script>



        <script src="jst/jquery.nice-select.min.js"></script>

        <script src="jst/owl.carousel.min.js"></script>


        <script src="jst/slick.min.js"></script>
        <script src="jst/jquery.counterup.min.js"></script>
        <script src="jst/waypoints.min.js"></script>

        <script src="jst/jquery.ajaxchimp.min.js"></script>
        <script src="jst/jquery.form.js"></script>
        <script src="jst/jquery.validate.min.js"></script>
        <script src="jst/mail-script.js"></script>





      </Helmet>




      <div className="body_bg">

        <header className="main_menu single_page_menu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navcus navbar-expand-lg navbar-light">
                  <Link className="navbar-brand" to="/"> <img src="img/logo-alt.ico" alt="logo" /> </Link>
                  <img src="img/escrita.ico" alt="logo" />
                  <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span className="menu_icon"><i className="fa-solid fa-bars"></i></span>
                  </button>



                  <div className="collapse navbar-collapse main-menu-item" id="navbarSupportedContent">
                    <ul className="navbar-nav">

                      <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to="" id="navbarDropdown"
                          role="button" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false">
                          Account
                        </Link>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                          <Link className="dropdown-item" to="/recoverpassword"> Recover Password</Link>
                          <Link className="dropdown-item" to="/login">Login</Link>
                        </div>
                      </li>

                      <li className="nav-item">
                        <a className="nav-link" href="#top-end">Contact</a>
                      </li>



                    </ul>
                  </div>
                  <Link to="/login" className="btn_1 d-none d-sm-block">Login</Link>
                </nav>
              </div>
            </div>
          </div>
        </header>

        <section className="banner_part">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6 col-xl-5">
                <div className="banner_text">
                  <div className="banner_text_iner">
                    <h1>Awakened Minds, Seeking Answers</h1>
                    <p>You understand and accept the real shape of the earth.
                      You realized that the Bible is the truth and that the world is controlled by evil.
                      Don't stop here, understand that there are more lies and that the flat earth is just the tip of the iceberg</p>
                    <a href="#sign" className="btn_1">Help us</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-5">
                <div className="banner_text">
                  <div className="banner_text_iner">

                    <video className="fora text-center" width="350" height="380" controls>
                      <source src="img/finalprojectcerto.mp4" type="video/mp4" />
                    </video>


                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>

        <section className="about_us section_padding">
          <div className="container">
            <div className="row align-items-center justify-content-between">

              <div className="col-md-5 col-lg-6 col-xl-6">
                <div className="learning_img">
                  <img src="img/map.png" alt="" />
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-5">
                <div className="about_us_text">
                  <h2>The Controllers</h2>
                  <p>The controllers do not want you to know the truth about our origin and our connection to the creator. To imprison your mind they made us believe that we are just cosmic dust without purpose.</p>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_us section_padding">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7 col-lg-6 col-xl-5">
                <div className="about_us_text">
                  <h2>What happened to ancient civilizations?</h2>
                  <p>Uncover the enigma surrounding ancient civilizations. We present a diverse array of content delving into the mysteries of the ancient world, including information deliberately concealed from the general public. Explore forgotten technologies and delve into suppressed spiritual teachings—all. Embark on a journey to unveil the untold narratives that shed light on what truly transpired in ancient civilizations.</p>

                </div>
              </div>
              <div className="col-md-5 col-lg-6 col-xl-6">
                <div className="learning_img">
                  <img src="img/about_img.png" alt="" />
                </div>
              </div>

            </div>
          </div>
        </section>









        <section className="gallery_part section_padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5">
                <div className="section_tittle text-center">
                  <h2>Did a catastrophic event occur that reset everything?</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="gallery_part_item">
                  <div className="grid">
                    <div className="grid-sizer"></div>
                    <span
                      className="grid-item bg_img img-gal grid-item--height-1 grid-a"
                      style={{ backgroundImage: "url('img/gallery/gallery_item_1.png')" }}>

                    </span>
                    <span className="grid-item bg_img img-gal grid-b"
                      style={{ backgroundImage: "url('img/gallery/gallery_item_2.jpeg')" }}>

                    </span>
                    <span className="grid-item bg_img img-gal grid-c"
                      style={{ backgroundImage: "url('img/gallery/gallery_item_3.png')" }}>

                    </span>
                    <span className="grid-item bg_img img-gal grid-item--height-2 grid-d"
                      style={{ backgroundImage: "url('img/gallery/gallery_item_5.jpg')" }}>

                    </span>
                    <span
                      className="grid-item bg_img img-gal grid-item--height-2 grid-e"
                      style={{ backgroundImage: "url('img/gallery/gallery_item_7.jpeg')" }}>

                    </span>
                    <span className="grid-item bg_img img-gal grid-item--width-1 grid-f"
                      style={{ backgroundImage: "url('img/gallery/gallery_item_6.png')" }}>

                    </span>
                    <span className="grid-item bg_img img-gal sm_weight  grid-item--height-1 grid-g"
                      style={{ backgroundImage: "url('img/gallery/gallery_item_4.jpg')" }}>

                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="about_us section_padding ">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-5 col-lg-6 col-xl-6">
                <div className="learning_img">
                  <video autoPlay={true} muted={true} loop={true} width={380} height={380} playsInline={''}><source src={Mao} type="video/mp4" /></video>

                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-5">
                <div className="about_us_text">
                  <h2>Contribution Opportunity
                  </h2>
                  <p>
                    Through your contribution, you'll unlock a trove of articles, videos, and documentaries delving into the mysteries of the past, unraveling the hidden truths behind ancient knowledge kept from the public. Your support not only grants you access to this exclusive content but also plays a vital role in furthering our mission to uncover and share this concealed wisdom with the world. Consider making a contribution today to help us continue our exploration and dissemination of these invaluable insights.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="client_logo">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="client_logo_slider owl-carousel d-flex justify-content-between">

                  <div class="single_client_logo">
                    <img src="img/topicos/top1.png" alt="" />
                  </div>
                  <div class="single_client_logo">
                    <img src="img/topicos/top2.png" alt="" />
                  </div>
                  <div class="single_client_logo">
                    <img src="img/topicos/top3.png" alt="" />
                  </div>
                  <div class="single_client_logo">
                    <img src="img/topicos/top4.png" alt="" />
                  </div>
                  <div class="single_client_logo">
                    <img src="img/topicos/top5.png" alt="" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4 col-lg-4 col-xl-4">
                <div className="banner_text">
                  <div className="banner_text_iner">

                    <video className="fora text-center" width="350" height="380" controls>
                      <source src="img/pedaco23.mp4" type="video/mp4" />
                    </video>




                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-4">
                <div className="banner_text">
                  <div className="banner_text_iner">

                    <video className="fora text-center" width="350" height="380" controls>
                      <source src="img/pedaco.mp4" type="video/mp4" />
                    </video>



                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-4">
                <div className="banner_text">
                  <div className="banner_text_iner">

                    <video className="fora text-center" width="350" height="380" controls>
                      <source src="img/video2.mp4" type="video/mp4" />
                    </video>



                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>

        <section id="sign" className="pricing_part padding_top section_padding fundoverde">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_tittle text-center">
                  <h2>Aid in the Quest:</h2>

                  <p>Your small contribution can make a big impact. Every contribution helps us continue this exploration together.</p>

                </div>
              </div>
            </div>
            <div id="" className="row justify-content-center">

              {/* <div className="col-lg-3 col-sm-6">
                <div className="single_pricing_part">
                  <p>Help Keep The Website And App - Subscription</p>
                  <h3>$5.00</h3>
                  <ul>
                    <p>Videos</p>
                    <p>Documentaries</p>
                    <p>Images</p>
                    <p>Books</p>

                  </ul>

                  <button
                    className="btn_2"
                    onClick={redirectA}
                    disabled={isLoadingA}
                  >


                    <span className="text">{isLoadingA ? "Loading..." : "sign up"}</span>

                  </button>
                </div>
              </div> */}

              <div className="col-lg-3 col-sm-6">
                <div className="single_pricing_part">
                  <p>6 months access - one-time payment</p>
                  <h3>$17.00</h3>
                  <ul>
                    <p>Videos</p>
                    <p>Documentaries</p>
                    <p>Images</p>
                    <p>Books</p>
                  </ul>
                  <button
                    className="btn_2"
                    onClick={redirectB}
                    disabled={isLoadingB}
                  >


                    <span className="text">{isLoadingB ? "Loading..." : "sign up"}</span>

                  </button>
                </div>
              </div>


              <div className="col-lg-3 col-sm-6">
                <div className="single_pricing_part">
                  <p>Lifetime Access - one-time payment</p>
                  <h3>$27.00</h3>
                  <ul>
                    <p>Videos</p>
                    <p>Documentaries</p>
                    <p>Images</p>
                    <p>Books</p>
                  </ul>
                  <button
                    className="btn_2"
                    onClick={redirectC}
                    disabled={isLoadingC}
                  >


                    <span className="text">{isLoadingC ? "Loading..." : "sign up"}</span>

                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>





      </div>
      <div id="top-end" className="section footerone px-3">

        <div className="container">

          <div className="row">

            <div className="col-md-6">

              <div className="footer-widget about-widget">
                <div className="footer-logo">
                  <p>Website and APP created to compile and store the world's censored truth.</p><br />
                  <img src={Logo} alt="" />  <span>
                    <Link className="text-white" to="/policy">  Privacy Policy</Link>
                  </span>

                </div>

              </div>










            </div>
            <div className="col-md-6">
              <div className="footer-widget subscribe-widget">
                <div className="widget-title">
                  <h2 className="title">Contact us!</h2>
                </div>
                <form action="contact.php" method="post">
                  <input className="input" name="email" type="email" placeholder="Your email here" />
                  <textarea className="input form-control mt-2" rows="3" name="message" type="text" placeholder="Leave your message" />
                  <button type="submit" className="input-btn">send</button>
                </form>
              </div>
            </div>

            <div className="col-md-3">

              <div className="footer-widget">
                <div className="widget-title">
                  <h2 className="title"></h2>
                </div>




              </div>

            </div>





          </div>

        </div>
        <div id="bottom-footer" className="section text-center">

          <div className="container">

            <div className="row">





              <div className="col-md-12 col-md-pull-12">
                <div className="footer-copyright">
                  <span>
                    Copyright &copy;All rights reserved Flat Earth Files
                  </span>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>











    </>

  );
};

export default Checkout;
