import firebase from 'firebase';


const firebaseConfig = {
  apiKey: "AIzaSyAqDg5lhFSh1SsZpnjyjwNAC84JwtUU2M8",
  authDomain: "teste-60b67.firebaseapp.com",
  projectId: "teste-60b67",
  storageBucket: "teste-60b67.appspot.com",
  messagingSenderId: "839223229617",
  appId: "1:839223229617:web:e6105a3d111ed1979b3690"
};

export default firebase.initializeApp(firebaseConfig);
