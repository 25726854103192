import React, { useState, useEffect } from 'react';
import Logo from '../navbar/logo-alt.ico';
import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';

function Footer({ Key, titulo, arquivo, visualizacoes, data, hora }) {

	const [urlImagem, setUrlImagem] = useState([]);


	useEffect(() => {
		firebase.storage().ref(`videos/${arquivo}`).getDownloadURL().then(url => setUrlImagem(url));

		console.log('carregou algo');
	}, []);



	return (






		<>
			<div id="top-footer" className="section">

				<div className="container">

					<div className="row">

						<div className="col-md-4">

							<div className="footer-widget about-widget">
								<div className="footer-logo">
									<Link to="/home" className="logo"><img src={Logo} alt="" /></Link>
									<p>App created to store videos and images that because they contain the truth are deleted from the internet</p>
								</div>
							</div>



						






						</div>
						<div className="col-md-4">
						<div className="footer-widget subscribe-widget">
							<div className="widget-title">
								<h2 className="title">Send us video and image tips!</h2>
							</div>
							<form action="contact.php" method="post">
								<input className="input" name= "email" type="email" placeholder="Your email here" />
								<textarea className="input form-control mt-2"  rows="3" name= "message" type="text" placeholder="Leave your message" />
								<button type="submit" className="input-btn">send</button>
							</form>
						</div>
						</div>

						<div className="col-md-3">

							<div className="footer-widget">
								<div className="widget-title">
									<h2 className="title">Last video added</h2>
								</div>


								<article className="article widget-article">
									<div className="article-img">
										<Link to={"/play/" + Key}>
											<img src={urlImagem} alt="" />
										</Link>
									</div>
									<div className="article-body">
										<h4 className="article-title"><Link to={"/play/" + Key}>{titulo}</Link></h4>
										<ul className="article-meta">
											<li><i className="fa fa-calendar"></i> {data}</li>
											<li><i className="fa fa-eye"></i> {visualizacoes}</li>
											<li><i className="fa fa-clock-o"></i> {hora}</li>
										</ul>
									</div>
								</article>

							</div>

						</div>



						

					</div>

				</div>

			</div>



			<div id="bottom-footer" className="section">

				<div className="container">

					<div className="row">





						<div className="col-md-12 col-md-pull-12">
							<div className="footer-copyright">
								<span>
									Copyright &copy;All rights reserved Redpill Files
								</span>
							</div>
						</div>

					</div>

				</div>

			</div>

		</>

	)
}
export default Footer;