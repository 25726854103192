import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import Navbar from '../../components/navbar/';
import Footer from '../../components/footer';


function Play(props) {


	const [video, setVideo] = useState({});
	const [urlVideo, setUrlVideo] = useState({});
	const usuarioEdit = useSelector(state => state.usuarioEmail);
	const [excluido, setExcluido] = useState(0);

	const [arquivosFooter, setArquivosFooter] = useState([]);
	let listaarquivosFooter = [];

	function remover() {

		firebase.firestore().collection('arquivos').doc(props.match.params.id).delete().then(() => {
			setExcluido(1);
		})
	}

	firebase.firestore().collection('arquivos').orderBy('data', 'desc').limit(1).get().then(async (resultado) => {

		await resultado.docs.forEach(doc => {




			listaarquivosFooter.push({
				id: doc.id,
				...doc.data()

			})






		})
		setArquivosFooter(listaarquivosFooter);
	})



	useEffect(() => {
		firebase.firestore().collection('arquivos').doc(props.match.params.id).get().then(async (resultado) => {
			setVideo(resultado.data())
			firebase.firestore().collection('arquivos').doc(props.match.params.id).update('visualizacoes', resultado.data().visualizacoes + 1)
			await firebase.storage().ref(`videos/${resultado.data().imagem}`).getDownloadURL().then(url => setUrlVideo(url));
		});


	}, [])
	return (
		<>
			<head>
				<meta charset="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />


				<link type="text/css" rel="stylesheet" href="css/bootstrap.min.css" />



				<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css" rel="stylesheet" />


				<link rel="stylesheet" href="css/font-awesome.min.css" />


				<link type="text/css" rel="stylesheet" href="css/style.css" />
				<link href="img/flatfiles.ico" rel="shortcut icon" />

				<title>Flat Earth Files</title>
			</head>
			<Navbar />
			{excluido ? <Redirect to='/' /> : null}
			<div className='container mt-5'>
				<article className="article article-post">

					<div className="article-main-img">

						<div className="embed-responsive embed-responsive-16by9">
							{
								video.tipo === 'books' ? 
									<iframe width="100%" height="100%" src={urlVideo}></iframe>
									: <iframe width="100%" height="100%"

										src={urlVideo} allow="autoplay" allowFullScreen="true">

									</iframe>

							}


						</div>



					</div>
					<div className="article-body">
						<ul className="article-info">
							<li className="article-category"><Link to='/'>Home</Link></li>
							<li className="article-type"><i className="fa fa-file-text"></i></li>

							{

								usuarioEdit === 'brenovilefort2a@gmail.com' ?
									<li className='article-type'><Link to={`/editararquivo/${props.match.params.id}`} ><i className="fa fa-pen-square text-white"></i></Link></li>

									: ''
							}

							{

								usuarioEdit === 'brenovilefort2a@gmail.com' ?

									<button onClick={remover} className="btn btn-block btn-lg btn-remove" type="button" >Remover</button>
									: ''
							}

						</ul>
						<h1 className="article-title">{video.titulo}</h1>
						<ul className="article-meta">
							<li><i className="fa fa-clock-o"></i> {video.data}</li>
							<li><i className="fa fa-eye"></i> {video.visualizacoes}</li>
							{/* <li><i className="fa fa-download"></i> To download click on the three dots in the video</li> */}
						</ul>
						<p>{video.detalhes}</p>
					</div>

				</article>


			</div>
			<footer id="footer">
				{arquivosFooter.map(item => <Footer Key={item.id} titulo={item.titulo} arquivo={item.arquivo} data={item.data} detalhes={item.detalhes} hora={item.hora} tipo={item.tipo} visualizacoes={item.visualizacoes} />)}

			</footer>
		</>
	)
}
export default Play;