import React from 'react';
import './navbar.css';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Logo from '../navbar/logo-alt.ico';
import Escrita from '../navbar/escrita.ico'

function Navbar(){

    const dispatch = useDispatch();
    const logado = useSelector(state=> state.usuarioLogado);
    const adm = useSelector(state=> state.usuarioEmail);

    return(
        <div id="nav-header">
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                
                <Link to={"/home"}><img src={Logo}></img></Link><img className="escrita" src={Escrita} />
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars text-white"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className=" nav navbar-nav">
                        <li className="nav-item"><Link className="nav-link"  to="/home">HOME</Link></li>
                       {
                           
                        logado > 0 && adm === "brenovilefort2a@gmail.com" ?
                        <>
                            
                            <li className="nav-item"><Link className="nav-link"  to="/eventocadastro">PUBLISH VIDEO</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/imagemcadastro">PUBLISH IMAGE</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/1/new-world-order">NEW WORLD ORDER</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/2/hidden-story">HIDDEN STORY</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/3/pandemic">PANDEMIC</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/4/flat-earth">FLAT EARTH</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/5/health">HEALTH</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/6/free-energy">FREE ENERGY</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/7/books">BOOKS</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/images">IMAGES</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="" ><a onClick={()=>dispatch({type: 'LOG_OUT'})} >LOG OUT</a></Link></li>
                        
                        </>
                        : logado > 0  ?
                        <>

                            
                            <li className="nav-item"><Link className="nav-link"  to="/files/1/new-world-order">NEW WORLD ORDER</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/2/hidden-story">HIDDEN STORY</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/3/pandemic">PANDEMIC</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/4/flat-earth">FLAT EARTH</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/5/health">HEALTH</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/6/free-energy">FREE ENERGY</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/7/books">BOOKS</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/images">IMAGES</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/unsubscribe">ACCOUNT</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="" ><a onClick={()=>dispatch({type: 'LOG_OUT'})} >LOG OUT</a></Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/donation" >Donation</Link></li>

                        </>
                       : 
                        <>
                            <li className="nav-item"><Link className="nav-link"  to="/files/1/new-world-order">NEW WORLD ORDER</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/2/hidden-story">HIDDEN STORY</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/3/pandemic">PANDEMIC</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/4/flat-earth">FLAT EARTH</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/5/health">HEALTH</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/6/free-energy">FREE ENERGY</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/files/7/books">BOOKS</Link></li>
                            <li className="nav-item"><Link className="nav-link"  to="/images">IMAGES</Link></li>
                            
   
                       </>
                        }
                        

                    </ul>
                </div>
            </div>
        </nav>
        </div>
    )
}

export default Navbar;