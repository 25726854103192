import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { store, persistor } from '../src/store';
import { Provider } from 'react-redux';
/*PAGINAS*/
import Login from './view/login/';
import NovoUsuario from './view/usuario-novo/';
import Home from './view/home/';
import UsuarioRecuperarSenha from './view/usuario-recuperar-senha/';
import EventoCadastro from './view/evento-cadastro/';
import Play from './view/play/';
import ImagemDia from './view/imagens-do-dia';
import ImagemCadastro from './view/imagem-cadastro';
import { PersistGate } from 'redux-persist/integration/react';
import ExibeImagem from './view/exibe-imagem';
import Checkout from './components/checkout/';
import Success from './components/sucess/';
import Cancel from './components/cancel/';
import Donation from './view/donation';
import Unsubscribe from './view/unsubscribe';
import Policy from './view/policy';

import {HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider>
        <Router>
          <Route exact path='/home' component={Home} />
          <Route path='/files/1/:parametro' component={Home} />
          <Route path='/files/2/:parametro' component={Home} />
          <Route path='/files/3/:parametro' component={Home} />
          <Route path='/files/4/:parametro' component={Home} />
          <Route path='/files/5/:parametro' component={Home} />
          <Route path='/files/6/:parametro' component={Home} />
          <Route path='/files/7/:parametro' component={Home} />
          <Route exact path='/' component={Checkout}/>
            <Route path='/cancel' component={Cancel}/>
            <Route path='/success' component={Success}/>
          <Route exact path='/newuser/sign/cod/userwascrthtx27' component={NovoUsuario} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/policy' component={Policy} />
          <Route exact path='/recoverpassword' component={UsuarioRecuperarSenha} />
          <Route exact path='/eventocadastro' component={EventoCadastro} />
          <Route exact path='/imagemcadastro' component={ImagemCadastro} />
          <Route exact path='/donation' component={Donation} />
          <Route exact path='/unsubscribe' component={Unsubscribe} />
         
          <Route path='/play/:id' component={Play} />
          <Route path='/showimage/:id' component={ExibeImagem} />
          <Route path='/editararquivo/:id' component={EventoCadastro} />
          <Route path='/editarimagem/:id' component={ImagemCadastro} />
          <Route exact path='/images' component={ImagemDia} />
        </Router>
        </HelmetProvider>
      </PersistGate>
    </Provider>
    
  );
}

export default App;
