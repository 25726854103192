import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';


function ExibeImagem(props) {


	const [video, setVideo] = useState({});
	const [urlVideo, setUrlVideo] = useState({});
	const usuarioEdit = useSelector(state => state.usuarioEmail);
	const [excluido, setExcluido] = useState(0);

	const [arquivosFooter, setArquivosFooter] = useState([]);
    let listaarquivosFooter = [];

	function remover() {

		firebase.firestore().collection('fotos').doc(props.match.params.id).delete().then(() => {
			setExcluido(1);
		})
	}
	firebase.firestore().collection('arquivos').orderBy('data', 'desc').limit(1).get().then(async (resultado) => {

		await resultado.docs.forEach(doc => {

			
			   

					listaarquivosFooter.push({
						id: doc.id,
						...doc.data()

					})
				



			

		})
		setArquivosFooter(listaarquivosFooter);
	})



	useEffect(() => {
		firebase.firestore().collection('fotos').doc(props.match.params.id).get().then(async (resultado) => {
			setVideo(resultado.data())
			firebase.firestore().collection('fotos').doc(props.match.params.id).update('visualizacoes', resultado.data().visualizacoes + 1)
			await firebase.storage().ref(`imagens/${resultado.data().arquivo}`).getDownloadURL().then(url => setUrlVideo(url));
		});


	}, [])
	return (
		<>
			<Navbar />
			{excluido ? <Redirect to='/' /> : null}
			<div className='container margem-image'>
				<article className="article article-post mg-auto">
					
					<div className="article-main-img">

						<div className="embed-responsive image3">
							<img src = {urlVideo}/>
						</div>



					</div>
					<div className="article-body">
						<ul className="article-info">
							<li className="article-category"><Link to='/'>Home</Link></li>
							<li className="article-type"><i className="fa fa-file-text"></i></li>
							{

								usuarioEdit === 'brenovilefort2a@gmail.com' ?
									<li className='article-type'><Link to={`/editarimagem/${props.match.params.id}`} ><i className="fa fa-pen-square text-white"></i></Link></li>

									: ''
							}

							{

								usuarioEdit === 'brenovilefort2a@gmail.com' ?

									<button onClick={remover} className="btn btn-block btn-lg btn-remove" type="button" >Remover</button>
									: ''
							}

						</ul>
						<h1 className="article-title">{video.titulo}</h1>
						<ul className="article-meta">
							<li><i className="fa fa-clock-o"></i> {video.data}</li>
							<li><i className="fa fa-eye"></i> {video.visualizacoes}</li>
						</ul>
						<p>{video.detalhes}</p>
					</div>

				</article>


			</div>
			<footer id="footer">
            {arquivosFooter.map(item => <Footer Key={item.id} titulo={item.titulo} arquivo={item.arquivo} data={item.data} detalhes={item.detalhes} hora={item.hora} tipo={item.tipo} visualizacoes={item.visualizacoes} />)}
                
            </footer>
		</>
	)
}
export default ExibeImagem;