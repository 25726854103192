import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import firebase from '../../config/firebase';


function Imagens({ Key, titulo, detalhes, arquivo, visualizacoes, data, hora }) {
    const [urlImagem, setUrlImagem] = useState([]);



    useEffect(() => {
        firebase.storage().ref(`imagens/${arquivo}`).getDownloadURL().then(url => setUrlImagem(url));

        console.log('carregou algo');
    }, [urlImagem, arquivo]);
    return (
        <>
            <div className="col-md-3 col-sm-6">
                <article className="article">
                    <div className="article-img article-img3">
                        <Link to={"/showimage/" + Key}>
                            <img src={urlImagem} alt="" />
                        </Link>
                    </div>
                    <div className="article-body">
                        <ul className="article-info">
                            
                            <li className="article-type"><i className="fa fa-camera"></i></li>
                        </ul>
                        <h3 className="article-title text-white"> <Link to={"/showimage/" + Key}>
                            {titulo}
                        </Link></h3>
                        <ul className="article-meta">
                            <li><i className="fa fa-calendar" aria-hidden="true"></i> {data}</li>
                            <li><i className="fa fa-clock-o"></i> {hora}</li>
                            <li><i className="fa fa-eye"></i> {visualizacoes}</li>
                        </ul>
                        
                    </div>
                    <p>{detalhes}</p>
                </article>
            </div>
        </>
    )
}

export default Imagens;